export default {
  avatar: "https://i.imgur.com/hDxBAxI.png",
  name: "ComparaSemplice.it",
  conversion: {
    value: 10,
    currency: "EUR",
  },
  pages: [
    {
      id: "24ubfe7s",
      style: {
        backgroundColor: "#f98722",
      },
      blocks: [
        {
          type: "text",
          props: {
            text: "👋 Ciao, benvenuto/a!",
            layout: {
              top: 80,
              width: "100%",
              fontSize: 27,
              fontWeight: 800,
              textAlign: "center",
              color: "white",
            },
          },
        },
        {
          type: "text",
          props: {
            text:
              "👉 Vuoi trovare la migliore tariffa Luce e Gas per risparmiare fino a 300€ all'anno?💰",
            layout: {
              top: 175,
              left: 54,
              lineHeight: 1.2,
              width: 300,
              fontSize: 23,
              rotate: -5,
              color: "white",
            },
          },
        },
        {
          type: "buttons",
          props: {
            buttons: [
              {
                text: "Magari! 😍",
                class: "animate__animated animate__pulse animate__infinite",
              },
              {
                text: "Mostrami come fare 😱",
                class: "animate__animated animate__pulse animate__infinite",
              },
            ],
            layout: {
              top: 350,
            },
          },
        },
        {
          type: "image",
          props: {
            url: "https://media1.giphy.com/media/WraJqGwrIYzAglP6UJ/giphy.gif",
            layout: {
              top: 540,
              left: 120,
              width: 40,
              rotate: 200,
            },
          },
        },
      ],
    },
    {
      id: "gfd7gydhw",
      style: {
        backgroundColor: "#f98722",
      },
      blocks: [
        {
          type: "text",
          props: {
            text:
              "ℹ Per mostrarti le offerte per risparmiare sulla tua linea telefonica e per poterti contattare gratuitamente e senza impegno avrei bisogno del tuo consenso alla nostra privacy",
            layout: {
              top: 130,
              left: 25,
              width: 350,
              fontSize: 20,
              rotate: 0,
              textAlign: "center",
              color: "white",
            },
          },
        },
        {
          type: "buttons",
          props: {
            buttons: [
              {
                text: "Visualizza privacy policy",
                url:
                  "https://www.comparasemplice.it/privacy?_ga=2.175751073.203956862.1584095529-86241690.1583850575&amp;_gac=1.251599348.1584095529.Cj0KCQjw3qzzBRDnARIsAECmryqrBJ1SxjcnkaG3XN7b1-ZlJL764V0JIf7_fv3DK4hsNoqfH7f4sm8aArF8EALw_wcB",
                layout: {
                  fontWeight: 300,
                  backgroundColor: "transparent",
                  padding: 10,
                  border: "none",
                  fontSize: 12,
                  color: "#d5d5d5",
                },
              },
              {
                text: "Acconsento 👍",
                class: "animate__animated animate__pulse animate__infinite",
                layout: {
                  backgroundColor: "#4bb238",
                  color: "#fff",
                  border: "3px solid #fff",
                  borderRadius: 0,
                },
              },
              {
                text: "Non acconsento",
                toast: {
                  message:
                    "Ci dispiace che tu abbia negato il consenso. SENZA consenso non potremo contattarti per inviarti le promozioni",
                  appearance: "error",
                },
                layout: {
                  fontWeight: 300,
                  backgroundColor: "transparent",
                  padding: 10,
                  border: "none",
                  fontSize: 12,
                  color: "#d5d5d5",
                },
              },
            ],
            layout: {
              top: 330,
            },
          },
        },
      ],
    },
    {
      id: "102jsdf34",
      style: {
        backgroundColor: "#f98722",
      },
      blocks: [
        {
          type: "text",
          props: {
            text: "🔥 Ci siamo!",
            layout: {
              top: 80,
              left: 80,
              width: 280,
              fontSize: 24,
              fontWeight: 800,
              rotate: -3,
              color: "white",
            },
          },
        },
        {
          type: "text",
          props: {
            text: `Prima di dirtela avrei bisogno di sapere che gestore hai attualmente? Cosi da proporti la migliore promo sul mercato ✅
            `,
            layout: {
              top: 160,
              left: 30,
              width: 340,
              fontSize: 16,
              color: "white",
            },
          },
        },
        {
          type: "buttons",
          props: {
            field: "provider",
            buttons: [
              {
                text: "Optima",
                value: "Optima",
                class: "",
                layout: {
                  fontWeight: 300,
                  padding: 15,
                  fontSize: 14,
                },
              },
              {
                text: "IREN",
                value: "IREN",
                class: "",
                layout: {
                  fontWeight: 300,
                  padding: 15,
                  fontSize: 14,
                },
              },
              {
                text: "ENEL",
                value: "ENEL",
                class: "",
                layout: {
                  fontWeight: 300,
                  padding: 15,
                  fontSize: 14,
                },
              },
              {
                text: "ENI",
                value: "ENI",
                class: "",
                layout: {
                  fontWeight: 300,
                  padding: 15,
                  fontSize: 14,
                },
              },
            ],
            layout: {
              top: 330,
              width: 160,
              left: 30,
            },
          },
        },
        {
          type: "buttons",
          props: {
            field: "provider",
            buttons: [
              {
                text: "Altro",
                value: "Altro",
                class: "",
                layout: {
                  fontWeight: 300,
                  padding: 15,
                  fontSize: 14,
                },
              },
              {
                text: "Nessuno",
                value: "Nessuno",
                class: "",
                layout: {
                  fontWeight: 300,
                  padding: 15,
                  fontSize: 14,
                },
              },
              {
                text: "Prima attivazione",
                value: "Prima attivazione",
                class: "",
                layout: {
                  fontWeight: 300,
                  padding: 15,
                  fontSize: 14,
                },
              },
            ],
            layout: {
              top: 330,
              width: 160,
              left: 205,
            },
          },
        },
      ],
    },

    {
      id: "102jsdf34",
      style: {
        backgroundColor: "#f98722",
      },
      blocks: [
        {
          type: "text",
          props: {
            text: `Una piccola info, quanto persone vivono a casa tua?
            `,
            layout: {
              top: 120,
              left: 40,
              width: 320,
              fontSize: 18,
              color: "white",
            },
          },
        },
        {
          type: "buttons",
          props: {
            field: "spend",
            buttons: [
              {
                text: "1-2",
                value: "1-2",
                class: "",
                layout: {
                  fontWeight: 300,
                  padding: 20,
                  fontSize: 18,
                },
              },
              {
                text: "2-4",
                value: "2-4",
                class: "",
                layout: {
                  fontWeight: 300,
                  padding: 20,
                  fontSize: 18,
                },
              },
              {
                text: "5 o più",
                value: "5more",
                class: "",
                layout: {
                  fontWeight: 300,
                  padding: 20,
                  fontSize: 18,
                },
              },
            ],
            layout: {
              top: 280,
              width: 320,
              left: 30,
            },
          },
        },
      ],
    },
    {
      id: "gdf67shfksf2",
      style: {
        backgroundColor: "#f98722",
      },
      blocks: [
        {
          type: "text",
          props: {
            text:
              "Abbiamo quasi finito, sto per mostrarti l'offerta più vantaggiosa per le tue esigenze 😉",
            layout: {
              lineHeight: 1.3,
              top: 79,
              left: 40,
              width: 310,
              textAlign: "center",
              fontSize: 20,
              color: "white",
            },
          },
        },
        {
          type: "input",
          props: {
            field: "address",
            text: "🚀 Inserisci in basso il tuo comune di residenza! 🏠",
            inputType: "text",
            placeholder: "es.: Milano",
            layout: {
              top: 200,
            },
          },
        },
        {
          type: "image",
          props: {
            url:
              "https://media2.giphy.com/media/aNqEFrYVnsS52/200w.webp?cid=ecf05e47ccaf6c4d79ba2586019fac8b437256ef26e14012&rid=200w.webp",
            layout: {
              top: 400,
              left: 0,
              width: 393,
            },
          },
        },
      ],
    },

    {
      id: "gdf67shfsf2",
      style: {
        backgroundColor: "#f98722",
      },
      blocks: [
        {
          type: "text",
          props: {
            text: "Ottimo, ma ora presentiamoci: come ti chiami? 😀",
            layout: {
              lineHeight: 1.3,
              top: 79,
              left: 40,
              width: 310,
              textAlign: "center",
              fontSize: 24,
              color: "white",
            },
          },
        },
        {
          type: "input",
          props: {
            field: "name",
            text: "Inserisci il tuo nome e cognome qui in basso! ⬇",
            inputType: "text",
            placeholder: "es.: Mario Rossi",
            layout: {
              top: 200,
            },
          },
        },
        {
          type: "image",
          props: {
            url:
              "https://media3.giphy.com/media/R6xi8dXsRhIjK/giphy.gif?cid=ecf05e47ova2t85k7nbbk07i7o1e9tib9qnavycgkux43obf&rid=giphy.gif",
            layout: {
              top: 400,
              left: 0,
              width: 393,
            },
          },
        },
      ],
    },

    {
      id: "3mfsd9fsdj8",
      style: {
        backgroundColor: "#f98722",
      },
      blocks: [
        {
          type: "text",
          props: {
            text: "Puoi scrivermi qui sotto il tuo numero di telefono ?",
            layout: {
              top: 86,
              left: 70,
              width: 240,
              textAlign: "center",
              fontSize: 18,
              color: "white",
            },
          },
        },
        {
          type: "input",
          props: {
            field: "phone",
            text: "Inserisci il tuo numero di telefono qua:",
            inputType: "phone",
            placeholder: "es.: 3401234567",
            countryCode: "+39",
            layout: {
              top: 200,
            },
          },
        },
        {
          type: "image",
          props: {
            url:
              "https://media3.giphy.com/media/JIX9t2j0ZTN9S/giphy.gif?cid=ecf05e47wn89yjvfz47aam97lttm8aftu82pbdmrp38u59j8&rid=giphy.gif",
            layout: {
              top: 303,
              left: 0,
              width: 393,
            },
          },
        },
      ],
    },
    {
      id: "456jn5i43n5",
      style: {
        backgroundColor: "#f98722",
      },
      disableBackNavigation: true,
      blocks: [
        {
          type: "image",
          props: {
            url: "https://media1.giphy.com/media/l3vR4yk0X20KimqJ2/giphy.gif",
            layout: {
              top: 0,
              left: 0,
              width: 393,
              height: 625,
            },
          },
        },
        {
          type: "text",
          props: {
            text:
              "Perfetto, un nostro operatore provvederà a contattarti entro pochi minuti!",
            layout: {
              top: 140,
              left: 46,
              width: 300,
              fontSize: 32,
              rotate: 0,
              lineHeight: 1.3,
              backgroundColor: "#000",
              paddingTop: 30,
              paddingBottom: 30,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 5,
              color: "white",
              textAlign: "center",
            },
          },
        },
        {
          type: "webhook",
          props: {
            url:
              "https://brain-api.spinup.media/track/campaign/c2467438-3ac0-464f-a499-c8b0deee0dff/lead",
            method: "POST",
            params: {
              client_id: "d01f6250-f23f-44d7-8c40-85d0fc76aaa6",
              client_privacy: "true",
            },
          },
        },
      ],
    },
  ],
};
