import React from "react";
import { Box, Flex, Text } from "rebass";
import _ from "lodash";
import { css } from "emotion";
import Block from "../block";
import { getBoxSize } from "../../utils";
import useStories from "../../hooks/stories";
import Navigator from "../navigator";

const Stories = () => {
  const {
    avatar,
    name,
    pages,
    currentStoryIndex,
    isInputFocused,
    dispatch,
  } = useStories();

  const getCurrentStory = () => pages[currentStoryIndex];

  return (
    <>
      <Flex
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            width: getBoxSize().width,
            height: getBoxSize().height,
            ...getCurrentStory().style,
          }}
        >
          <Flex
            flexDirection="row"
            alignItems="center"
            className={css`
              position: absolute;
              top: 15px;
              left: 15px;
              z-index: 20;
            `}
          >
            <img
              className={css`
                border-radius: 35px;
                height: 35px;
                width: auto;
                margin-right: 10px;
              `}
              alt={name}
              src={avatar}
            />

            <Text
              sx={{
                color: "white",
                fontWeight: 700,
              }}
            >
              {name}
            </Text>
          </Flex>

          <Box
            sx={{
              display: ["block", "none"],
            }}
          >
            <Navigator />
          </Box>

          <Box
            className={css`
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              transition: filter 0.15s ease, backdrop-filter 0.15s ease;

              ${isInputFocused &&
              `
                backdrop-filter: blur(10px);
                filter: blur(10px);
              `}
            `}
          >
            {getCurrentStory()
              .blocks.filter(
                (block) => ["input", "buttons"].indexOf(block.type) === -1
              )
              .map((block, index) => (
                <Block
                  key={`${getCurrentStory().id}-${index}`}
                  type={block.type}
                  {...block.props}
                />
              ))}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                width: "50%",
              }}
              onClick={() => {
                if (isInputFocused || getCurrentStory().disableBackNavigation) {
                  return;
                }

                dispatch({
                  type: "PAGE_PREVIOUS",
                });
              }}
            />

            <Box
              className="next"
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                width: "50%",
              }}
              onClick={() => {
                if (
                  !_.filter(
                    getCurrentStory().blocks,
                    (block) => ["input", "buttons"].indexOf(block.type) > -1
                  ).length
                ) {
                  return dispatch({
                    type: "PAGE_NEXT",
                  });
                }

                dispatch({
                  type: "SHOW_INTERACTION_FEEDBACK_TOGGLE",
                  payload: true,
                });

                setTimeout(() => {
                  dispatch({
                    type: "SHOW_INTERACTION_FEEDBACK_TOGGLE",
                    payload: false,
                  });
                }, 600);
              }}
            />
          </Box>

          {getCurrentStory()
            .blocks.filter(
              (block) => ["input", "buttons"].indexOf(block.type) !== -1
            )
            .map((block, index) => (
              <Block
                key={`${getCurrentStory().id}-${index}`}
                type={block.type}
                {...block.props}
              />
            ))}
        </Box>

        <Box
          sx={{
            display: ["none", "block"],
          }}
        >
          <Navigator />
        </Box>
      </Flex>
    </>
  );
};

export default Stories;
